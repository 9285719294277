import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import './signup.css'

import { getCountries } from '../../apis/Country';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signUpUser } from '../../apis/Authentication';
import { Toaster, toast } from 'sonner'


const RegisterPage = () => {

    const dispatch = useDispatch()
    const signUpAction = useSelector((state) => state.action)
    const [countries, setCountries] = useState([])
    const [formErrors, setFormErrors] = useState()

    const [termsModal, setTermsModal] = useState(false)
    const [privacyModal, setPrivacyModal] = useState(false)

    let navigate = useNavigate()
    const [userDetails, setUserDetails] = useState({
        firstName: "",
        lastName: "",
        companyName: "",
        country: "Kenya",
        emailAddress: "",
        phoneNumber: "",
        password: "",
        confirmPassword: ""
    });

    const handleOnChange = (e) => {
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        getCountries().then(resp => {
            if (resp?.status === 200) {
                resp.data.sort((a, b) => a.name.common.localeCompare(b.name.common));
                setCountries(resp?.data)
            }
        })

    }, [])
   
    const loading = () => {
        return <div class="lds-dual-ring"></div>
    }

    const validate = () => {
        let errors = {}
        if (!userDetails?.firstName) {
            errors['firstName'] = 'Field is required!'
        }
        if (!userDetails?.lastName) {
            errors['lastName'] = 'Field is required!'
        }

        if (!userDetails?.phoneNumber) {
            errors['phoneNumber'] = 'Field is required!'
        }
        
        if (!userDetails?.emailAddress) {
            errors['emailAddress'] = 'Field is required!'
        }
        else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(userDetails?.emailAddress)) {
            errors['emailAddress'] = 'Invalid email address. E.g. example@email.com'
        }
        if (!userDetails?.password) {
            errors['password'] = 'Field is required!'
        }
        if (!userDetails?.confirmPassword) {
            errors['confirmPassword'] = 'Field is required!'
        }
        if (userDetails?.confirmPassword !== userDetails?.password) {
            errors['password'] = 'Password do not match!'
        }
        setFormErrors(errors)
        return errors
    };


    const handleSignUpUser = () => {
        if (Object.keys(validate()).length === 0) {
            signUpUser(userDetails, dispatch).then(resp => {
                console.log(resp)
                if (resp?.status === 200) {
                        toast.success("Account created successful. Check your email for account confirmation.", { theme: "colored" })
                        setTimeout(() => {
                            navigate('/auth/login')
                          }, 6000);   
                }
                else { toast.error("Error occured!", { theme: "colored" }) }
            })
        }
    }

    return (
        <div>
        <Toaster position="top-right" expand={false} richColors/>
        <img src={process.env.PUBLIC_URL + "/images/logo.png"} alt="welcome-logo" className="company-login" />

        <div className='signup-page'>

             
        <div className='signup-left-side'>
                <div className='signup-left-content'>
                    <p className='left-content-header'>Do More on WhatsApp with Lipachat's Whatsapp API</p>
                    <ul id='content-list'>
                        <li id='content-item'>Build bots to automate customer service and marketing</li>
                        <li id='content-item'>Send broadcasts to all your contacts in minutes</li>
                        <li id='content-item'>Manage your contacts</li>
                        <li id='content-item'>Save money by saving time</li>
                    </ul>
                    <a id='website-link' href='https://lipachat.com/' target="_blank" rel="noreferrer">Learn More</a>
                </div>
            </div>



            <div className='signup-form-section'>
                <form id='form-modal' className='signup-form'>
                    <p className='signup-welcome-header'>Create account</p>
                    <div className='inputs-section'>

                    <div className='signup-input-group'>
                        <label className='signup-label'>First Name*</label>
                        <input type="text" name='firstName' className='signup-input' onChange={(e) => handleOnChange(e)} />
                        <span id='form-error'>{formErrors?.firstName}</span>
                    </div>

                    <div className='signup-input-group'>
                        <label className='signup-label'>Last Name*</label>
                        <input type="text" name='lastName' className='signup-input' onChange={(e) => handleOnChange(e)} />
                        <span id='form-error'>{formErrors?.lastName}</span>
                    </div>

                    <div className='signup-input-group'>
                        <label className='signup-label'>Email Address*</label>
                        <input type="email" name='emailAddress' className='signup-input' onChange={(e) => handleOnChange(e)} />
                        <span id='form-error'>{formErrors?.emailAddress}</span>
                    </div>

                    <div className='signup-input-group'>
                        <label className='signup-label'>Phone Number*</label>
                        <input type="number" name='phoneNumber' className='signup-input' onChange={(e) => handleOnChange(e)} />
                        <span id='form-error'>{formErrors?.phoneNumber}</span>
                    </div>
                   
                    <div className='signup-input-group'>
                        <label className='signup-label'>Company/Institution Name</label>
                        <input type="text" name='companyName' className='signup-input' onChange={(e) => handleOnChange(e)} />
                        <span id='form-error'>{formErrors?.companyName}</span>
                    </div>

                        <div className="signup-input-group">
                            <label className='signup-label'>Country*</label>
                            <select name='country' className='signup-input' defaultValue={'Kenya'} onChange={(e) => handleOnChange(e)}>
                                <option selected disabled hidden value="Kenya">Kenya</option>
                                {countries.map((country, id) =>
                                    <option id='option' key={id} style={{ fontSize: '14px' }} value={country.name.common}>{country.name.common}</option>
                                )}
                            </select>
                            <span className="error">{formErrors?.country}</span>
                        </div>

                        <div className='signup-input-group'>
                        <label className='signup-label'>Password*</label>
                        <input type="password" name='password' className='signup-input' onChange={(e) => handleOnChange(e)} />
                        <span id='form-error'>{formErrors?.password}</span>
                    </div>

                    <div className='signup-input-group'>
                        <label className='signup-label'>Confirm Password*</label>
                        <input type="password" name="confirmPassword" className='signup-input' onChange={(e) => handleOnChange(e)} />
                        <span id='form-error'>{formErrors?.password}</span>
                    </div>

                </div>

                   <div className='signup-actions'>
                        <p className="signup-section">Already a Member? <NavLink to="/auth/login" className="login-link">Login</NavLink></p>
                    </div>


                    <div className='signup-action-btns'>
                    {!signUpAction.pending ?
                            <button type='button' className="signup-button" onClick={handleSignUpUser}>Sign Up</button> :
                            <button type='button' className="signup-button-disable" disabled="disabled">{loading()}</button>
                        } 
                    </div>

                    <div className='terms-condition-section'>
                        <p className='terms-conditions'>By clicking Sign Up, you agree to our <span id='terms-privacy' onClick={() =>setTermsModal(true)}>Terms</span> and have
                         read and acknowledged our <span id='terms-privacy' onClick={() =>setPrivacyModal(true)}>Privacy Policy</span>.</p>
                    </div>
                    
                </form>
            </div>
        </div>

        
     {/* //////// Terms modal */}
    <div className={termsModal ? 'create-modal-active' : 'create-modal-inactive'} >
        <form id="form-modal" className='view-modal-content'>
          <p className='modal-title'> Terms & Conditions
          <span id='close-button' class="material-symbols-outlined" onClick={() =>setTermsModal(false)}>close</span>
            </p>
         

          <div className='terms-condition-modal'>
<h2 id="termsofuse">TERMS OF USE</h2>
<p><strong>THESE TERMS OF USE (“TERMS”) ARE A BINDING CONTRACT BETWEEN YOU AND FINWIT LTD (“WE”, “US”), FOR A BULK WHATSAPP PLATFORM (HENCEFORTH REFERRED TO AS THE “Platform”). BY SUBSCRIBING TO THE PLATFORM, YOU AGREE TO THESE TERMS ON BEHALF OF YOURSELF AS AN INDIVIDUAL USER, OR ON BEHALF OF THE ORGANIZATION YOU REPRESENT (“CUSTOMER”, “YOU)</strong></p>
<h2 id="usageofplatform">Usage of Platform</h2>
<h3 id="1services">1. Services</h3>
<ol>
<li><p>We provide you with a platform interface and a related set of services (“Services”) which can enable you to subscribe and use the same to help you improve your business process(es) in an as-is condition.</p></li>
<li><p>On registration, and subject to the agreement, we will grant to you the right to access and use the Platform, and any related functionality, in accordance with the Terms mentioned in this document.</p></li>
<li><p>Your use of the Services is also governed by (and subject to) different platform policies that we can be integrated with and which are hereby incorporated by reference and are a part of these Terms.</p>
<ul>
<li>Facebook:-&nbsp;<a href="https://developers.facebook.com/policy">https://developers.facebook.com/policy</a></li>
<li>WhatsApp :-&nbsp;<a href="https://www.whatsapp.com/legal/business-terms/">https://www.whatsapp.com/legal/business-terms/</a></li>
<li>Telegram:-&nbsp;<a href="https://core.telegram.org/">https://core.telegram.org/</a></li>
<li>Kik:-&nbsp;<a href="https://www.kik.com/developers/">https://www.kik.com/developers/</a></li>
<li>Viber:-&nbsp;<a href="https://www.viber.com/terms/viber-developer-distribution-agreement/">https://www.viber.com/terms/viber-developer-distribution-agreement/</a></li>
<li>Line:-&nbsp;<a href="https://developers.line.me/">https://developers.line.me/</a></li>
<li>Skype:-<a href="https://www.botframework.com/Content/Developer-Code-of-Conduct-for-">https://www.botframework.com/Content/Developer-Code-of-Conduct-for-</a></li>
<li>Microsoft-Bot-Framework.htm</li>
<li>Slack:-&nbsp;<a href="https://api.slack.com/developer-policy">https://api.slack.com/developer-policy</a></li></ul></li>
<li><p>You are solely responsible and liable for complying with the Platform Policies that you opt for building your platform and we bear no commercial or any other liability or responsibility for the users that access the platform and the data that gets created. By using the platform you implicitly agree to and accept all of the Terms and Conditions, or you will lose the right to use the the platform and services. Your using the Services in any way means that you agree to some and not all of these Terms, and these Terms will remain in effect while you use the Services.</p></li>
</ol>
<h3 id="2limitationsofservice">2.Limitations of Service</h3>
<ol>
<li>It is acknowledged and agreed upon that you are completely responsible for evaluating the integrity, quality, accuracy or reliability of any data provided to us before making/ implementing any decisions based on this information and any consequences that arise out of this.</li>
<li>You acknowledge that we do not assume any liability for any data handled/generated by you on the platform</li>
<li>The licenses granted herein are only for the purpose of allowing you to connect to and use the Services for your personal or internal business use. You will not use the services to perform natural language processing for any third parties.</li>
</ol>
<h3 id="3temporarysuspensionofservices">3.Temporary Suspension of Services</h3>
<ol>
<li>We may temporarily limit or suspend the Services from time to time at our discretion including to perform upgrades to, and maintenance of, the platform.</li>
<li>We also hold the rights to terminate access to the platform based on any missed payments as per contractual agreements forwarded to you. Your access to the platform and/or subscription or other payments for use of the platform represents an agreement to abide by the commercial terms and conditions of the contract forwarded to you, the usage Terms and Conditions listed in this document.</li>
</ol>
<h3 id="4unacceptableuseofservicesanduserconduct">4. Unacceptable Use of Services and User Conduct</h3>
<ol>
<li>You represent, warrant, and agree that you will not create and / or contribute any Content or User Submission or otherwise create any platforms or use the Services in a manner that:<ol>
<li>Infringes or violates the intellectual property rights or any other rights of anyone else ;</li>
<li>Violates any law or regulation, including any applicable export control laws;</li>
<li>Is harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise objectionable;</li>
<li>Jeopardizes the security of your account or anyone else’s (such as allowing someone else to log in to the Services as you);</li></ol></li>
<li>Attempts, in any manner, to obtain the password, account, or other security information from any other user;<ol>
<li>Violates the security of any computer network, or cracks any passwords or security encryption codes;</li>
<li>Runs Maillist, Listserv, any form of auto-responder or “spam” on the Services, or any processes that run or are activated while you are not logged into the Services, or that otherwise interfere with the proper working of the Services (including by placing an unreasonable load on the Services’ infrastructure);</li>
<li>“Crawls,” “scrapes,” or “spiders” any page, data, or portion of or relating to the</li>
<li>Services or Content (through use of manual or automated means);</li>
<li>Copies or stores any significant portion of the Content;</li>
<li>Decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or information of or relating to the Services.</li>
<li>Is non compliant with any international agreements on Global Data Privacy Regulations (GDPR) and The Children’s Online Privacy Protection Act (“COPPA”) and any of its revisions, changes or impacts that are listed and will be communicated to you in case of any changes from the current regulation</li>
<li>A violation of any of the foregoing is grounds for termination of your right to use or access the Services, with or without notice and surrender of your contact information, data and intelligence that you have built on the platform, subject to legal notices received by a regulatory body received in such a situation.</li></ol></li>
</ol>
<h3 id="5compliancewithlaws">5.Compliance with Laws</h3>
<ol>
<li>You shall comply with all applicable laws including the ones around protection of personal information and data privacy.</li>
<li>You are responsible for obtaining any consent required by law from your users to allow the use of their personal information (if required) for use the our services, in accordance with the general terms, privacy policy and Data Processing Agreement.</li>
</ol>
<h3 id="6compliancebyusers">6.Compliance by Users</h3>
<ol>
<li>You will need to ensure that your Users are informed that they are governed by, and hence comply with, all applicable laws, including laws governing the protection of personal information.</li>
</ol>
<h3 id="7contentmonitoring">7.Content Monitoring</h3>
<ol>
<li>Any information or content publicly posted or privately transmitted through the Services, and any chatbots created using the platform, are the sole responsibility of the person from whom such content is originated. You should access all such information and content at your own risk, and we aren’t liable for any errors or omissions in that information or content or for any damages or loss you might suffer in connection with it.</li>
<li>We cannot control and have no duty to take any action regarding how you may interpret and use the Content or what actions you may take as a result of having been exposed to the Content. You hereby release us from all liability for you having acquired or not acquired Content through the Services. We can’t guarantee the identity of any users with whom you interact in using the Services and are not responsible for which users gain access to the platform.</li>
<li>You are responsible for all Content you contribute, in any manner, to the Services, and you represent and warrant you have all rights necessary to do so, in the manner in which you contribute it. You will keep all your registration information accurate and current. You are responsible for all your activity in connection with the Services.</li>
<li>The Services may contain links or connections to third party websites or services that are not owned or controlled by us. When you access third party websites or use third party services, you accept that there are risks in doing so, and that we are not responsible for such risks. We encourage you to be aware of this and to read the terms and conditions and privacy policy of each third party website or service that you visit or utilize. You are solely responsible for complying with the terms and conditions, any liability or commercial conditions set forth and arising from any third party provider access that you connect to via the Platform</li>
<li>We have no control over, and assume no responsibility for, the content, accuracy, privacy policies, or practices of or opinions expressed in any third party websites or by any third party that you interact with through the Services. In addition, we will not and cannot monitor, verify, censor or edit the content of any third party site or service. By using the Services, you release and hold us harmless from any and all liability arising from your use of any third party website or service.</li>
<li>Your interactions with organizations and/or individuals found on or through the Services, including payment and delivery of goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such organizations and/or individuals. You should make whatever investigation you feel necessary or appropriate before proceeding with any online or offline transaction with any of these third parties. You agree that we shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings.</li>
<li>If there is a dispute between participants on this site, or between users and any third party, or a dispute arising out of violation of terms and conditions set forth here for the use of the Platform, you agree that we are under no obligation to become involved. In the event that you have a dispute with one or more other users, you release us, our officers, employees, agents, and successors from claims, demands, and damages of every kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising out of or in any way related to such disputes and/or our Services.</li>
</ol>
<h3 id="8dataprocessingaccessandbackups">8.Data Processing, Access and Backups</h3>
<ol>
<li>We may utilize the services of multiple sub processors for the purpose of providing the services.</li>
<li>Any data collected by us through our services shall be as defined by us in the Data Processing Agreement</li>
<li>It is advisable that you take all required backups of your data prior to requesting a downgrade (or termination) in service</li>
</ol>
<h3 id="9subscriptioncancellation">9.Subscription Cancellation</h3>
<ol>
<li>All subscription cancellations will be done manually.</li>
<li>You may cancel your account(s) at any point of time. If you wish so, please reach out to us for processing cancellations.</li>
<li>No refunds will be processed for subscription cancellations.</li>
</ol>
<h3 id="10disclaimerofwarranty">10.Disclaimer of Warranty</h3>
<ol>
<li><strong>Risk</strong><ol>
<li>Neither we, nor our licensors or suppliers, make any representations or warranties regarding suggestions or recommendations of services or products offered or purchased through the Services. We are not responsible for any links to third party websites from the Service and the inclusion of any link does not imply an endorsement of a third party website or service by us.</li>
<li>The services and content are provided by us (and our licensors and suppliers) on an “as-is” basis, without warranties of any kind, either express or implied, including, without limitation, implied warranties of merchantability, fitness for a particular purpose, non-infringement, or that use of the services will be uninterrupted or error-free.</li>
<li>Any use of the services is at your own risk. the software may contain bugs, errors. we do not warrant the performance of the services, that the services will operate uninterrupted or error-free, or that the services will operate in accordance with any accompanying documentation</li></ol></li>
<li><strong>Use of Internet</strong><ol>
<li>Our Services are provided over the Internet. As such, the Services are subject to the operation of the Internet and telecommunications infrastructures as well as the operation of your Internet connection services, all of which are beyond our control.</li>
<li>We do not warrant that the services will be uninterrupted or that you will be able to access or use the Services at the location and times of your choosing.</li></ol></li>
</ol>
<h3 id="11liabilitylimits">11.Liability Limits</h3>
<ol>
<li>To the fullest extent allowed by applicable law, under no circumstances and under no legal theory (including, without limitation, tort, contract, strict liability, or otherwise) shall we (our its licensors or suppliers) be liable to you or to any other person for (a) any indirect, special, incidental, or consequential damages of any kind, including damages for lost profits, loss of goodwill, work stoppage, accuracy of results, or computer failure or malfunction.</li>
<li>We shall not be liable for any indirect, consequential, exemplary, incidental, special or punitive damages, including loss of profits.</li>
</ol>
<h3 id="12indemnity">12.Indemnity</h3>
<ol>
<li>To the fullest extent allowed by applicable law, you agree to indemnify and hold us, and our affiliates, officers, agents, employees, and partners harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys’ fees) arising from or in any way related to any third party claims relating to (a) your use of the Services (including any actions taken by a third party using your account, your violation of these Terms or your infringement upon any intellectual property or other proprietary right of any person or entity.</li>
<li>In the event of such a claim, suit, or action (“Claim”), we will attempt to provide notice of the Claim to the contact information we have for your account (provided that failure to deliver such notice shall not eliminate or reduce your indemnification obligations hereunder).</li>
<li>We may, at our own expense, assume the defence and control of any matter otherwise subject to indemnification by you. Doing so shall not excuse your indemnity obligations in this Agreement. The terms of this paragraph will survive any termination or cancellation of the Agreement.</li>
</ol>

</div>


          <div className='dialog-footer'>
            
            <div>
                <button type='button' className="submit-btn" onClick={() =>setTermsModal(false)}>Close</button>
            </div>

          </div>
        </form>
      </div>


 {/* //////// privacy modal */}
 <div className={privacyModal ? 'create-modal-active' : 'create-modal-inactive'} >
        <form id="form-modal" className='view-modal-content'>
          <p className='modal-title'> Privacy Policy
          <span id='close-button' class="material-symbols-outlined" onClick={() =>setPrivacyModal(false)}>close</span>
            </p>
        

          <div className='terms-condition-modal'>
     <h2 id="privacypolicyforthecustomersoflipachatafrica">Privacy Policy for the Customers of Lipachat Africa</h2>
<p>This Privacy Policy describes how Lipachat Africa (“we,” “us,” or “our”) collects, uses, and shares personal information obtained from customers (“you” or “your”) when you use our software. We are committed to protecting your privacy and ensuring the security of your personal information. By using our software, you consent to the practices described in this Privacy Policy.</p>
<h3 id="informationwecollect">Information We Collect:</h3>
<p><strong>Personal Information:</strong></p>
<p>We may collect the following types of personal information from you:</p>
<ul>
<li>Contact information (such as name, email address, phone number)</li>
<li>Account information (such as username, password)</li>
<li>Payment information (such as credit card details)</li>
<li>Usage information (such as IP address, device information, browser type)</li>
<li>Communication preferences (such as your marketing preferences)</li>
</ul>
<p><strong>Non-Personal Information:</strong>
We may collect non-personal information about your use of our software, such as aggregated and anonymized data for analytics purposes.</p>
<h3 id="howweuseyourinformation">How We Use Your Information:</h3>
<p><strong>Provision of Services:</strong>
We may use your personal information to:</p>
<ul>
<li>Provide and maintain our software.</li>
<li>Process your transactions and deliver the requested services.</li>
<li>Respond to your inquiries and provide customer support.</li>
<li>Send you administrative information, including updates and notifications related - to our software.
<strong>Improving Our Services:</strong>
We may use your information to:</li>
<li>Understand and analyse usage patterns to enhance and customize our software.</li>
<li>Conduct research, develop new features, and improve the overall user experience.
Marketing and Communication:
With your consent, we may use your personal information to:</li>
<li>Send you promotional materials, newsletters, and marketing communications.</li>
<li>Inform you about new products, features, and updates.
You can opt-out of receiving marketing communications at any time by following the instructions provided in the communication or by contacting us directly.</li>
</ul>
<h3 id="informationsharing">Information Sharing:</h3>
<p><strong>Third-Party Service Providers:</strong>
We may share your personal information with trusted third-party service providers who assist us in operating our software, conducting business activities, and delivering services to you. These third parties are contractually obligated to keep your information confidential and use it solely for the purposes we specify.</p>
<p><strong>With Legal Obligations:</strong>
We may disclose your personal information to comply with applicable laws, regulations, legal processes, or enforceable governmental requests.</p>
<p><strong>Business Transfers:</strong>
In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred as part of the transaction. We will notify you via email or a prominent notice on our website in such circumstances.</p>
<p>In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred as part of the transaction. We may disclose or transfer your personal information to the acquiring entity or any other third party involved in the transaction. Your personal information may be one of the assets that are transferred or acquired in such circumstances.</p>
<p>We will notify you via email or through a prominent notice on our website of any such change in ownership or control of your personal information. We will also provide you with choices regarding your personal information, following any applicable laws and regulations.</p>
<p>During a business transfer, we will ensure that the receiving party agrees to protect your personal information in a manner consistent with this Privacy Policy. We will respond appropriately to ensure that any such transfer is conducted securely and in accordance with applicable privacy laws.</p>
<p>If you do not wish your personal information to be transferred to the acquiring entity or any other third party, you may contact us using the information provided in the “Contact Us” section below to exercise your right to object or seek further clarification about the transfer.</p>
<p>It is important to note that in the event of a business transfer, the new entity or third party may continue to process your personal information in accordance with their own privacy policies and practices, which may differ from this Privacy Policy. We encourage you to review the privacy policies of any new entity or third party before disclosing your personal information to them.
We will make reasonable efforts to ensure that any business transfer is conducted in a manner that protects the privacy and security of your personal information. However, we cannot guarantee that such transfers will always occur, and we deny any liability for any actions or omissions of third parties involved in the business transfer process.</p>
<p>If you have any questions or concerns regarding business transfers or the protection of your personal information during such transactions, please contact us using the information provided in the “Contact Us” section below. We will be happy to provide you with additional information and address any concerns you may have.</p>
<p><strong>Data Security:</strong>
We take the security of your personal information seriously and implement appropriate technical and organizational measures to protect it from unauthorized access, use, disclosure, alteration, or destruction. We strive to ensure the confidentiality, integrity, and availability of your data by following industry best practices and complying with applicable data protection regulations. We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, use, disclosure, alteration, or destruction. However, please note that no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>
<h3 id="hereishowweprioritizedatasecurity">Here is how we prioritize data security:</h3>
<p><strong>Secure Transmission:</strong>
We employ secure transmission protocols, such as HTTPS (Hypertext Transfer Protocol Secure), to encrypt data transmitted between your device and our software. This encryption helps safeguard your information against interception and unauthorized access during transmission.</p>
<p><strong>Encryption:</strong>
We utilize encryption techniques to protect sensitive information, such as payment details and passwords. Your data is encrypted both at rest (when stored on our servers) and in transit (when transmitted over networks). This encryption ensures that even if unauthorized individuals gain access to the data, it remains indecipherable and unusable.</p>
<p><strong>Access Controls:</strong>
We maintain strict access controls to limit access to your personal information to authorized personnel who have a legitimate need to access it. Access privileges are granted on a “need-to-know” basis and are regularly reviewed and updated. We also enforce strong authentication measures to protect against unauthorized access to your account.</p>
<p><strong>Firewalls and Intrusion Detection Systems:</strong>
We employ firewalls and intrusion detection systems to protect our software and infrastructure against unauthorized access, malicious attacks, and suspicious activities. These security measures help prevent unauthorized individuals from gaining access to our systems and your personal information.</p>
<p><strong>Data Minimization:</strong>
We only collect and retain the personal information necessary to fulfil the purposes outlined in this Privacy Policy. We do not store more data than is required and have implemented data minimization principles to reduce the risk associated with data storage.</p>
<p><strong>Employee Training and Awareness:</strong>
We provide comprehensive training to our employees to ensure they understand the importance of data security and their responsibilities in safeguarding your personal information. Our employees are trained on security best practices, managing sensitive data, and identifying and responding to potential security threats.</p>
<p><strong>Regular Security Assessments:</strong>
We conduct regular security assessments, including vulnerability scans and penetration testing, to identify and address any vulnerabilities in our software and infrastructure. These assessments help us proactively detect and mitigate potential security risks.</p>
<p><strong>Third-Party Security:</strong>
When we engage third-party service providers, we carefully evaluate their security practices and ensure that they have adequate measures in place to protect your personal information. We require our third-party providers to comply with applicable data protection regulations and maintain the confidentiality and security of your information.</p>
<p><strong>Incident Response:</strong>
In the event of a data breach or security incident, we have established incident response procedures to promptly investigate, mitigate, and notify affected individuals and relevant authorities as required by applicable laws and regulations. We take immediate action to address any security vulnerabilities and prevent future incidents.
Despite our rigorous efforts to secure your personal information, it is important to note that no method of transmission or storage is completely secure. While we strive to protect your data, we cannot guarantee its absolute security. If you believe that your interaction with our software is no longer secure or if you suspect any unauthorized access or use of your account, please notify us immediately using the contact information provided in the “Contact Us” section below.
We are committed to continuously improving our data security practices and staying up to date with the latest industry standards and developments in data protection.</p>
<p><strong>Your Rights and Choices:</strong>
You have certain rights regarding your personal information. These include:</p>
<ul>
<li>The right to access and rectify your personal information.</li>
<li>The right to request the erasure of your personal information.</li>
<li>The right to object to the processing of your personal information</li>
<li>The right to restrict the processing of your personal information.</li>
<li>The right to data portability</li>
</ul>
<p>To exercise these rights, please contact us using the information provided in the “Contact Us” section below. We will respond to your request in accordance with applicable data protection laws.</p>
<p><strong>Updates to this Privacy Policy:</strong>
We may update this Privacy Policy from time to time. Any changes will be effective upon posting the revised policy on our website. We encourage you to review this Privacy Policy periodically for any updates.</p>
<h3 id="contactus">Contact Us:</h3>
<p>If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us at:
Lipachat Africa
<a href="mailto:chat@lipachat.com">chat@lipachat.com</a>
Please allow up to 30 days for us to respond to your inquiries.</p>
</div>

          <div className='dialog-footer'> 
            <div>
                <button type='button' className="submit-btn" onClick={() =>setPrivacyModal(false)}>Close</button>
            </div>

          </div>
        </form>
      </div>


        </div>
    );
}

export default RegisterPage